@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Light.ttf');
}

body {
  font-family: 'Lato' !important;
} */